import React, { useState, useEffect } from "react";

const CountDown = ({ targetDate }) => {
  const isLessthanCurrent = new Date(targetDate) < new Date();

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const [pulse, setPulse] = useState({
    DAYS: false,
    HOURS: false,
    MINUTES: false,
    SECS: false,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetDate);
      const updatedPulse = {
        DAYS: newTimeLeft[0].count !== timeLeft?.[0].count,
        HOURS: newTimeLeft[1].count !== timeLeft?.[1].count,
        MINUTES: newTimeLeft[2].count !== timeLeft?.[2].count,
        SECS: newTimeLeft[3].count !== timeLeft?.[3].count,
      };

      setPulse(updatedPulse);
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, timeLeft]);

  function calculateTimeLeft(targetDate) {
    if (isLessthanCurrent) {
      return [
        {
          count: "0",
          format: "DAYS",
        },
        {
          count: "0",
          format: "HOURS",
        },
        {
          count: "0",
          format: "MINUTES",
        },
        {
          count: "0",
          format: "SECS",
        },
      ];
    }

    const now = new Date();
    const target = new Date(targetDate);

    const timeDiff = target.getTime() - now.getTime();

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return [
      {
        count: String(days).padStart(2, "0"),
        format: "DAYS",
      },
      {
        count: String(hours).padStart(2, "0"),
        format: "HOURS",
      },
      {
        count: String(minutes).padStart(2, "0"),
        format: "MINUTES",
      },
      {
        count: String(seconds).padStart(2, "0"),
        format: "SECS",
      },
    ];
  }

  const getPulseClass = (unit) => {
    return pulse[unit] ? "pulse" : "";
  };

  return (
    <div className="flex md:gap-8 gap-3">
      {timeLeft?.map((item, index) => (
        <div
          className="flex justify-center items-center flex-col md:gap-3 gap-1"
          key={index}
        >
          <h3
            className={`md:text-5xl text-2xl font-extrabold ${getPulseClass(
              item.format
            )}`}
          >
            {item.count}
          </h3>
          <p className="md:text-3xl text-lg font-normal">{item.format}</p>
        </div>
      ))}
    </div>
  );
};

export default CountDown;
