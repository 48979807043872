"use client";

import React, { memo } from "react";
import Navbar from "../shared/Navbar";
import HeroImageSrc from "../../assets/HeroSectionHome.webp";

const HeroSection = memo(({ scrollFunc }) => {
  return (
    <>
      {/* Preload Hero Image */}
      <head>
        <link rel="preload" href={HeroImageSrc} as="image" />
      </head>

      <div style={{ height: "661px" }} className="bg-gray-900">
        <Navbar />

        <div className="relative isolate pt-14">
          {/* Optimized Image */}
          <img
            alt="Hero Section Background"
            src={HeroImageSrc}
            className="absolute inset-0 -z-10 object-cover heroImage"
            width={1920}
            height={661} // Ensure explicit dimensions
            loading="eager"
            decoding="async"
            fetchpriority="high"
          />

          {/* Hero Content */}
          <div className="lg:px-24 p-12 max-w-4xl py-32">
            <div className="text-left">
              {/* Headline */}
              <h1 className="font-pt-sans mt-16 text-5xl md:text-[64px] font-bold tracking-normal text-white">
                Big Transformation <br /> Brave Actions
              </h1>

              {/* Subtext */}
              <p className="font-pt-sans text-2xl mt-6 leading-8 text-gray-100">
                Educate and skill 100 million learners by 2028
              </p>

              {/* Call to Action */}
              <div
                onClick={scrollFunc}
                role="button"
                className="relative bg-black bg-opacity-50 w-[180px] h-[46px] flex rounded-full px-3 py-1 mt-10 text-sm leading-6 text-gray-400 hover:ring-white/20 ring-1 ring-white"
                style={{ transition: "all 0.3s ease" }}
              >
                <span className="m-auto font-pt-sans text-xl text-white">
                  Know More <span aria-hidden="true">&rarr;</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default HeroSection;
