import React from "react";
import Card from "./Card";
import ResourceAugmentation from "../../../assets/ResourceAugmentation.webp";
import HireTrainDeploy from "../../../assets/Hire-Train-Deploy.webp";
import RecruitmentServices from "../../../assets/RecruitmentServices.webp";

const Services = () => {
  const cardData = [
    {
      title: "Resource Augmentation",
      description:
        "We provide skilled professionals to meet your project needs and enhance team capabilities",
      src: ResourceAugmentation,
    },
    {
      title: "Hire-Train-Deploy",
      description:
        "We recruit, train, and integrate skilled professionals to seamlessly join and support your team.",
      src: HireTrainDeploy,
    },
    {
      title: "Recruitment Services",
      description:
        "We provide recruitment services to find and place top talent for your organization.",
      src: RecruitmentServices,
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-wrap justify-around">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.src}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
