import { useRef } from "react";
import HeroSection from "../components/home/HeroSection";
import Business from "../components/home/Business/Index";
import Affiliations from "../components/home/Affiliations";
import Founder from "../components/home/Founder";

import Footer from "../components/shared/Footer";

const Home = () => {
  const businessSection = useRef(null);

  const scrollTo = () => {
    if (businessSection.current) {
      businessSection.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="flex flex-col">
      <HeroSection scrollFunc={scrollTo} />
      <Business businessSection={businessSection} />
      <Affiliations />
      <Founder />
      {/* <MediaAnnouncements /> */}
      {/* <ContactFooter /> */}
      <Footer />
    </div>
  );
};

export default Home;
