"use client";

import React from "react";
import {
  ChartBarIcon,
  AcademicCapIcon,
  UserGroupIcon,
  BriefcaseIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as Products } from "../../assets/Products.svg";
import { ReactComponent as Projects } from "../../assets/Projects.svg";
import { ReactComponent as Services } from "../../assets/Services.svg";
import { ReactComponent as Consulting } from "../../assets/Consulting.svg";
import { ReactComponent as Trainings } from "../../assets/Trainings.svg";
import AboutProducts from "../../assets/About_products.webp";
import AboutTrainings from "../../assets/About_trainings.webp";
import AboutConsulting from "../../assets/About_consulting.webp";
import AboutProjects from "../../assets/About_projects.webp";
import AboutServices from "../../assets/About_services.webp";

const businessData = [
  {
    title: "Products",
    description:
      "Providing cutting-edge education tech solutions, including resume-building courses and career tools, to fuel professional growth and success.",
    icon: <Products className="w-14 h-14 text-custom-gold mb-2" />,
  },
  {
    title: "Trainings",
    description:
      "Delivering specialized training in academics, employability skills, and corporate development, designed to boost knowledge and career readiness.",
    icon: <AcademicCapIcon className="w-12 h-12 text-custom-gold mb-4" />,
  },
  {
    title: "Consulting",
    description:
      "Providing expert consulting in leadership development, maturity models, and metrics management to accelerate organizational growth and efficiency.",
    icon: <Consulting className="w-12 h-12 text-custom-gold mb-4" />,
  },
  {
    title: "Projects",
    description:
      "Executing projects in government, information technology, and business process outsourcing sectors, delivering customized solutions for complex challenges.",
    icon: <Projects className="w-12 h-12 text-custom-gold mb-4" />,
  },
  {
    title: "Services",
    description:
      "Providing comprehensive resource augmentation, hire-train-deploy solutions, and recruitment services to effectively address and meet diverse talent needs across various industries.",
    icon: <Services className="w-16 h-16 text-custom-gold mb-4" />,
  },
];

const businessData1 = [
  {
    title: "Products",
    description:
      "We offer education tech solutions including resume builders, skill-building courses, and career tools to support professional growth and success.",
    src: AboutProducts,
  },
  {
    title: "Trainings",
    description:
      "We provide specialized trainings in academic subjects, employability skills, and corporate development, tailored to enhance knowledge and career readiness.",
    src: AboutTrainings,
  },
  {
    title: "Consulting",
    description:
      "We offer expert consulting services in leadership development, maturity models, metrics management, and more, designed to drive organizational growth and efficiency.",
    src: AboutConsulting,
  },
  {
    title: "Projects",
    description:
      "We execute projects across sectors like government, information technology, and business process outsourcing, delivering tailored solutions for complex challenges.",
    src: AboutProjects,
  },
  {
    title: "Services",
    description:
      "We provide resource augmentation, hire-train-deploy solutions, and recruitment services to fulfill diverse talent needs.",
    src: AboutServices,
  },
];

const Business = () => {
  return (
    <div className="flex flex-col items-center justify-center py-24">
      <h2 className="text-4xl font-pt-sans font-bold mb-20">Our Businesses</h2>

      <div className="flex flex-wrap justify-center gap-12 max-w-7xl">
        {businessData1.map((business, index) => (
          <div
            key={index}
            className="flex w-[350px] flex-col items-center text-center box-border p-3 border border-gray-200 rounded-lg shadow-md"
          >
            <img
              className="w-full rounded-lg"
              src={business.src}
              alt={business.title}
            />
            <h3 className="text-xl font-pt-sans font-bold px-8 pt-4 pb-2">
              {business.title}
            </h3>
            <p className="text-gray-500 leading-5 text-sm font-normal font-pt-sans mt-2 px-8 pb-4">
              {business.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Business;
