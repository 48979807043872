import pratyarpanLogo from "../assets/pratyarpanLogo.svg";
import topImage from "../assets/topEventImage.svg";
import bottomImage from "../assets/bottomEventImage.svg";
import check from "../assets/check.svg";
import CountDown from "../components/event/Countdown";
import { useRef, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TakeAwaysList = [
  {
    heading: "Turning Passion into Profession: Real-Life Success Stories",
  },
  {
    heading: "Navigating Career Paths: Tips from Industry Experts",
  },
  {
    heading: "Building Essential Skills for Your Dream Career",
  },
  {
    heading: "Overcoming Challenges and Staying Motivated",
  },
  {
    heading: "Actionable Steps to Kickstart Your Career Journey",
  },
];
const AttendCriteria = [
  {
    heading: "Students exploring different career paths",
  },
  {
    heading: "Individuals looking to turn their passions into professions",
  },
  {
    heading: "Anyone seeking guidance from experienced professionals",
  },
  {
    heading: "Those eager to learn about building a successful career",
  },
];

export default function Event() {
  const [inputField, setInputField] = useState({
    name: "",
    email: "",
    number: "",
  });
  const [isError, setIsError] = useState({
    name: false,
    email: false,
    number: false,
  });
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const form = useRef();

  function onChangeInputField(e) {
    let { name, value } = e.target;
    if (name === "number") {
      value = value.replace(/\D/g, "").slice(0, 10);
    }
    setInputField((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  async function onSubmit() {
    const { name, email, number } = inputField;
    const form = JSON.stringify(inputField);

    const newError = {
      name: name.length === 0,
      email: email.length === 0 || !emailRegex.test(email),
      number: number.length === 0,
    };

    setIsError(newError);

    if (!newError.name && !newError.email && !newError.number) {
      try {
        await fetch(
          "https://script.google.com/macros/s/AKfycbzlR7iAvxUcWvz9SQ9V2cSFawuKF7iRBd2kk_LEQZOOjRsVtiV7WTpvmMboxRck4Xi-7Q/exec",
          {
            mode: "no-cors",
            method: "POST",
            body: form,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setInputField({ name: "", email: "", number: "" });
        setIsBtnDisable(true);
        onSuccessToast();
      } catch (error) {
        console.error("Error:", error);
        alert("Error submitting the form");
      }
    }
  }

  function onFocusInputField(e) {
    const { name } = e.target;
    setIsError((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  }

  const scrollTo = () => {
    if (form.current) {
      form.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const onSuccessToast = () => {
    toast("Registered successfully");
  };

  return (
    <>
      <ToastContainer />
      <div className="w-full flex justify-center items-center flex-col gap-10 py-12 bg-[url('/src/assets/eventBg.svg')] bg-no-repeat bg-cover">
        <div className="w-full flex justify-center items-center flex-col gap-4">
          <img
            src={pratyarpanLogo}
            alt="logo"
            className="md:w-[130px] md:h-[130px] w-[80px]"
          />
          <p className="text-[#FFFFFF] md:text-2xl text-lg">
            Empowering Tomorrow, Today{" "}
          </p>
        </div>

        <div className="flex justify-center items-center flex-col gap-2 text-center text-white">
          <h1 className="md:text-5xl text-2xl leading-8 font-extrabold w-[30ch]">
            Passion to Profession:
            <br />
            Turning Aspirations into Careers
          </h1>
          {/* <h3 className="md:text-4xl font-normal">
            Facts, Myths and Essential Care
          </h3> */}
        </div>
        <button
          onClick={scrollTo}
          className="md:px-10 md:text-2xl px-8 py-2 text-lg font-bold text-[#64A460] bg-white border-0 rounded"
        >
          Register Now
        </button>
      </div>
      <div className="bg-[#64A460] flex md:justify-between items-center justify-center gap-8 md:flex-row flex-col md:px-[100px] px-[20px] md:py-12 py-5">
        <div className="flex flex-col md:gap-3 md:items-start justify-center items-center text-white">
          <h3 className="md:text-5xl text-2xl font-extrabold md:leading-[54px] leading-normal">
            February 7, 2025
          </h3>
          <p className="md:text-2xl text-lg font-normal">06:30 PM</p>
        </div>
        <div className="flex gap-10 text-white">
          <CountDown targetDate={"2025-02-07T18:30:00Z"} />
        </div>
      </div>
      <div className="relative">
        <img
          src={topImage}
          alt="top-image"
          className="absolute top-0 right-0 md:block hidden"
        />
        <img
          src={bottomImage}
          alt="bottom-image"
          className="absolute bottom-0 left-0 md:block hidden"
        />

        <div className="md:px-[100px] px-[20px] md:py-12 py-8 flex flex-col md:gap-6 gap-3 text-center">
          {/* <h3 className="md:text-5xl md:leading-[54px] text-2xl leading-8 font-bold text-[#252525]">
            Skin and Hair Wellness Simplified
          </h3> */}
          <div className="md:text-2xl text-base font-normal md:leading-[1.5] tracking-normal text-[#464646]">
            <p>
              Ready to turn your passion into a thriving career? Join us for an
              inspiring session where industry experts share their journeys,
              offer valuable insights, and guide you on transforming your
              aspirations into professional success!
            </p>
          </div>
        </div>
        <div className="md:px-[100px] px-[20px] md:py-12 py-6 flex justify-between items-start md:flex-row flex-col md:gap-[108px] gap-10">
          <div className="md:w-1/2 w-full flex flex-col gap-14">
            <div className="w-full flex flex-col gap-8">
              <h3 className="md:text-4xl text-2xl font-semibold text-[#252525] md:text-left text-center">
                Key Takeaways:
              </h3>
              <div className="flex flex-col gap-5 text-[#464646]">
                {TakeAwaysList.map((item, index) => (
                  <div className="flex flex-col gap-2" key={index}>
                    <div className="flex justify-start items-start gap-2">
                      <img src={check} className="md:w-6 md:h-6 w-4 h-4" />
                      <h4 className="md:text-xl text-base font-extrabold">
                        {item.heading}
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col gap-8">
              <h3 className="md:text-4xl text-2xl font-semibold text-[#252525] md:text-left text-center">
                Who Should Attend:
              </h3>
              <div className="flex flex-col gap-5 text-[#464646]">
                {AttendCriteria.map((item, index) => (
                  <div className="flex flex-col gap-2" key={index}>
                    <div className="flex justify-start items-center gap-2">
                      <img src={check} className="w-6 h-6" />
                      <h4 className="md:text-xl text-base font-extrabold">
                        {item.heading}
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-5">
            <h3 className="md:text-2xl text-lg text-[#64A460] text-center">
              Secure your spot today and start transforming your passion into a
              successful career!
            </h3>
            <div
              className="md:px-10 md:py-6 px-5 py-3 border-4 border-[#64A460] rounded-xl"
              ref={form}
            >
              <h4 className="md:text-4xl text-2xl font-bold mb-8 text-[#252525] text-center">
                Register Here
              </h4>
              <div className="w-full flex flex-col md:gap-6 gap-3 text-[#555555]">
                <div className="w-full flex flex-col md:gap-2 gap-1">
                  <label className="md:text-lg text-base font-normal">
                    Name*
                  </label>
                  <input
                    type="text"
                    className={`text-sm text-[#555555] rounded-lg md:px-7 md:py-4 px-5 py-2 outline-0 ${
                      isError?.name
                        ? "border-2 border-red-500"
                        : "border-[1px] border-[#C8C8C8]"
                    }`}
                    placeholder="Enter your name"
                    name="name"
                    value={inputField.name}
                    onChange={onChangeInputField}
                    onFocus={onFocusInputField}
                  />
                </div>
                <div className="w-full flex flex-col md:gap-2 gap-1">
                  <label className="md:text-lg text-base font-normal">
                    Email*
                  </label>
                  <input
                    type="email"
                    className={`text-sm text-[#555555] rounded-lg md:px-7 md:py-4 px-5 py-2 outline-0 ${
                      isError?.email
                        ? "border-2 border-red-500"
                        : "border-[1px] border-[#C8C8C8]"
                    }`}
                    placeholder="info@xyz.com"
                    name="email"
                    value={inputField.email}
                    onChange={onChangeInputField}
                    onFocus={onFocusInputField}
                  />
                </div>
                <div className="w-full flex flex-col md:gap-2 gap-1">
                  <label className="md:text-lg text-base font-normal">
                    Whatsapp No.*
                  </label>
                  <input
                    type="text"
                    className={`text-sm text-[#555555] rounded-lg md:px-7 md:py-4 px-5 py-2 outline-0 ${
                      isError?.number
                        ? "border-2 border-red-500"
                        : "border-[1px] border-[#C8C8C8]"
                    }`}
                    placeholder="**********"
                    name="number"
                    maxLength={10}
                    pattern="\d{10}"
                    value={inputField.number}
                    onChange={onChangeInputField}
                    onFocus={onFocusInputField}
                  />
                </div>
              </div>

              <div className="flex justify-center items-center">
                <button
                  disabled={isBtnDisable}
                  className={`md:px-10 md:text-xl px-8 py-2 md:mt-12 mt-6 font-bold border-0 rounded ${
                    isBtnDisable
                      ? "bg-[#b5b5b5] text-gray-700 cursor-not-allowed"
                      : "bg-[#64A460] text-white "
                  }`}
                  onClick={onSubmit}
                >
                  Register Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
