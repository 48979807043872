import React from "react";
import Card from "./Card";
import LeadershipWorkshops from "../../../assets/LeadershipWorkshops.webp";
import MaturityModel from "../../../assets/MaturityModels.webp";
import MetricsManagement from "../../../assets/MetricsManagement.webp";

const Consulting = () => {
  const cardData = [
    {
      title: "Leadership Workshops",
      description:
        "We empower teams with leadership skills like strategic thinking, communication, and team building through workshops.",
      src: LeadershipWorkshops,
    },
    {
      title: "Maturity Models",
      description:
        "We help organizations optimize processes with tailored frameworks, maturity models, assessments, and strategic recommendations.",
      src: MaturityModel,
    },
    {
      title: "Metrics Management",
      description:
        "Our consulting services help organizations establish metrics, collect data, and make data-driven decisions.",
      src: MetricsManagement,
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-wrap justify-around">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.src}
          />
        ))}
      </div>
    </div>
  );
};

export default Consulting;
