import React from "react";
import NSDC from "../../assets/NSDC.svg"; // Example SVG import, replace with actual paths
import Nasscom from "../../assets/Nasscom.svg";
import MSME from "../../assets/MSME.webp";
import CII from "../../assets/CII.svg";
import AIMA from "../../assets/AIMA.svg";
import AICTE from "../../assets/AICTE.svg";
import StartupIndia from "../../assets/StartupIndia.webp";
import ODOP from "../../assets/ODOP.svg";
import GEM from "../../assets/gem.webp";

const affiliationsData = [
  { name: "NSDC", icon: NSDC },
  { name: "Nasscom", icon: Nasscom },
  { name: "MSME", icon: MSME },
  { name: "CII", icon: CII },
  { name: "AIMA", icon: AIMA },
  { name: "AICTE", icon: AICTE },
  { name: "Startup India", icon: StartupIndia },
  { name: "GEM", icon: GEM },
  { name: "ODOP", icon: ODOP },
];

const Affiliations = () => {
  return (
    <div className="bg-[#FDF2DF] items-center flex flex-col py-12">
      <div className="flex max-w-4xl justify-center">
        <div>
          <h2 className="text-4xl font-bold text-center font-pt-sans my-12 text-gray-800">
            Our Affiliations
          </h2>
          <div className="flex flex-wrap justify-center gap-8">
            {affiliationsData.map((affiliation, index) => (
              <div
                key={index}
                className="flex items-center justify-center w-full sm:w-[calc(50%-1rem)] md:w-[calc(33.333%-1.5rem)] lg:w-[calc(20%-1.6rem)] h-24"
              >
                <img
                  src={affiliation.icon}
                  alt={affiliation.name}
                  className="max-h-full max-w-full object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affiliations;
