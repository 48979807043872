import { Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import Facebook from "../../assets/fb.svg";
import Insta from "../../assets/insta.svg";
import Twitter from "../../assets/twiter.svg";
import Linkedin from "../../assets/linkedin.svg";

const Footer = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-[#0648BF]">
      <div className="container mx-auto">
        <div className="flex md:justify-between items-center md:flex-row md:gap-0 flex-col gap-8 px-8 pt-8 md:pt-20 pb-8">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">CCC</span>
            <img alt="Company Logo" src={Logo} className="h-20 w-auto" />
          </Link>
          <nav className="flex space-x-4">
            <Link to="/" className="text-white" onClick={scrollTop}>
              Home
            </Link>
            <Link to="/about" className="text-white" onClick={scrollTop}>
              About Us
            </Link>
            <Link to="/contact" className="text-white" onClick={scrollTop}>
              Contact
            </Link>
          </nav>
          <div className="flex space-x-4">
            <Link
              //   to="https://www.facebook.com/yourfacebookpage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="Facebook" className="w-8 h-8" />
            </Link>
            <Link
              to="https://www.instagram.com/yourinstagrampage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Insta} alt="Instagram" className="w-8 h-8" />
            </Link>
            <Link
              to="https://www.instagram.com/yourinstagrampage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Twitter} alt="Instagram" className="w-8 h-8" />
            </Link>
            <Link
              to="https://in.linkedin.com/company/ccc-digital-private-limited"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Linkedin} alt="Instagram" className="w-8 h-8" />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex md:justify-end justify-center  items-center md:items-end text-white px-8 md:pb-20 pb-8">
        <div className="text-left flex flex-col gap-2">
          <a
            className="text-white md:w-[50ch] w-[40ch]"
            href="https://www.google.com/maps/dir//Level+1,+Elemental+%23337,+101,+Road+No.+1,+Malakunta,+Financial+District,+Hyderabad,+Nanakramguda,+Telangana+500032/@17.4131755,78.2611852,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bcb959d27444ec9:0x8a3ba1cae9a5a648!2m2!1d78.343587!2d17.4131924?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
          >
            CCC Digital Private Ltd 101, 1st Floor, Elemental 337, Road No. 1,
            Financial District, Hyderabad, Telangana 500032
          </a>
          <div className="flex gap-2">
            <a href="mailto:info@cccsnv.com" className="text-white">
              info@cccsnv.com
            </a>
            <a
              href="https://www.cccsnv.com"
              target="_blank"
              className="text-white"
            >
              www.cccsnv.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
