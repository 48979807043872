import HeroImage from "../assets/HeroSectionHome.webp";
import Navbar from "../components/shared/Navbar";

import Mail from "../assets/mail.svg";
import Location from "../assets/map-pin.svg";
import Footer from "../components/shared/Footer";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = JSON.stringify(formData);
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxH24ZcnUUb7T9cibs9IZtrGGpUl0yVrfK3rwCi4qgiYoYnMdWLxzOHPpYZ8KI-rNJ9/exec",
        {
          mode: "no-cors",
          method: "POST",
          body: form,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Your message has been saved");
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting the form");
    }
  };

  return (
    <>
      <Navbar />
      <ToastContainer />

      <div className="relative  pt-40 ">
        <img
          alt="background"
          src={HeroImage}
          className="absolute inset-0 -z-10 object-cover heroImage"
        />

        <div className="container mx-auto">
          <div className="flex justify-between items-stretch md:px-28 gap-4 md:gap-28 flex-col md:flex-row">
            <div className="flex-1 py-4 md:px-0 px-12">
              <h2 className="text-[32px] font-bold text-white mb-4">
                Contact Us
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-[1rem] font-medium text-white"
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[1rem] p-3"
                    placeholder="Type Full Name"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-[1rem] font-medium text-white"
                  >
                    Your Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[1rem] p-3"
                    placeholder="Type Email"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block text-[1rem] font-medium text-white"
                  >
                    Your Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={3}
                    value={formData.message}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[1rem] p-3"
                    placeholder="Type Message"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="inline-flex items-center justify-center rounded-full border border-white bg-transparent py-2 px-8 text-[1rem] font-medium text-white shadow-sm hover:bg-white hover:text-[#000000] focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 transition duration-300 ease-in-out transform hover:scale-110 "
                >
                  Send
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 12h16m-6-6l6 6-6 6"
                    />
                  </svg>
                </button>
              </form>
            </div>
            <div className="flex-1 flex items-center justify-center py-12">
              <div className=" bg-[#3953A4] p-12 py-16 h-full flex flex-col justify-between rounded-[5px]">
                <div className="flex items-start gap-2 flex-col">
                  <img src={Mail} alt="mail" />
                  <p className="text-white text-[20px] m-[0px]">
                    info@cccsnv.com
                  </p>
                </div>
                <div className="flex items-start gap-2 flex-col">
                  <img src={Location} alt="location" />
                  <p className="text-white text-xl">
                    CCC Digital Private Ltd 101, 1st Floor, Elemental 337, Road
                    No. 1, Financial District, Hyderabad, Telangana 500032.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
