import React from "react";
import Card from "./Card";
import RazorHireImage from "../../../assets/products_razorhire.webp";
import JayahoImage from "../../../assets/products_jayaho.webp";
import SkillGraphImage from "../../../assets/products_skillgraph.webp";

const Products = () => {
  const cardData = [
    {
      title: "RazorHire",
      description:
        "Simplify your resume experience with a user-friendly platform, offering tailored templates to boost your job applications.",
      src: RazorHireImage,
      url: "https://razorhire.ai",
    },
    {
      title: "Jayaho",
      description:
        "A platform that empowers students with essential coding, math, and tech skills through innovative and competitive courses.",
      src: JayahoImage,
      url: "https://jayaho.school",
    },
    {
      title: "Skill Graph",
      description:
        "A platform that enhances students' competitive coding skills to strengthen their resumes with notable achievements.",
      src: SkillGraphImage,
      url: "https://student.ccc.training",
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-wrap justify-around">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.src}
            isProduct={true}
            url={card.url}
          />
        ))}
      </div>
    </div>
  );
};

export default Products;
