import AboutTeam from "../../assets/AboutTeam.webp";

const Team = () => {
  return (
    <>
      <div className="relative w-[1312px] mx-auto mb-[70px]">
        <img
          src={AboutTeam}
          alt="Team"
          className="w-full h-auto object-cover"
        />

        <div className="absolute bottom-0 left-0 w-full h-[90%] bg-gradient-to-t from-black via-transparent to-transparent" />

        <div className="absolute bottom-5 left-0 right-0 text-center text-white z-10">
          <h3 className="text-[28px] font-bold w-[970px] mx-auto">
            We are a dedicated team of professionals delivering innovative
            education, consulting, and workforce solutions to drive success and
            growth.
          </h3>
        </div>
      </div>
    </>
    /*
        <div className="relative h-[533px] flex items-center justify-center">
        <img 
          src={TeamImage}
          alt="Team Image" 
          className="absolute inset-0 w-full object-cover"
        />
        <div className="relative z-10 text-left text-center mt-auto mb-12 max-w-4xl mx-auto">
         
          <p className="text-[28px] font-pt-sans font-bold leading-7 text-gray-100">
          We are a dedicated team of professionals delivering innovative education, consulting, and workforce solutions to drive success and growth.</p>
        </div>
      </div>
      */
  );
};

export default Team;
